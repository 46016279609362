import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import * as cryptoJS from 'crypto-js';
import * as jwt_decode from "jwt-decode";
import * as CryptoJS from 'crypto-js';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  passwordHide: boolean = true;
  invalidotp: boolean = false;
  userName: string;
  password: string;
  isSignInClick = false;
  isLoginError = false;
  private webUrl = environment.webURL;
  logintrue: boolean = false;
  
  encdataToEncrypt: any = {id:"abc123", name:'test'}
  encryptedData = "";
  secretKey = "mysecret"
  emailForm: FormGroup;
  sentmail: boolean = false
  signin: boolean = false
  otp: any;
  randomnumber: any;
  result: any;
  isUserAuthenticated: boolean;

  constructor(private authService: AuthService, private router: Router, private formBuilder: FormBuilder, private toastr: ToastrService) { }

  ngOnInit() {
    // let ezAutoTest = {
    //   "clientId": '6268efd75b51f5ed5487758e',
    //   "redirectUri": 'https://plspps.europassistance.in/ssoauthentication',
    //   "userId": '621cadcef5f1263d1f932bf2',
    //   "token": 'vR1dS828qHvPnZnFZpVlG7Ybw8GNLdOlpgJBvJp6qScQ3uhgezugP54oCbi1NjxB1hBd8SLNY4cnbGSkKKoq2ISoT9kPlSi6urQm0C1WZXAirTnEOd9JaePaQPggMaiEudEyp6vzizHU6StwmSTV4KOKBpxudFKKV64TEb357LjLEC7aGS9I3hr2kvCyBtcav2aA12xVS9eaqvaRTAe5eY2oXCVNmkhhQspWBvwrXuENcJqleia3BScFdkbP7mCB'
    // }
    // let test  = CryptoJS.AES.encrypt(JSON.stringify(ezAutoTest), this.secretKey).toString()

    // console.log(test, 'ezAuto encryption key');

    this.emailForm = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]],
    })
    if(this.isAuthenticated() != false) {
      if(sessionStorage.getItem('role') == "admin" || sessionStorage.getItem('role') == "superadmin"){
        this.logintrue = true;
        this.router.navigateByUrl('/admin/generalMasterReports')
      }      
      else if(sessionStorage.getItem('role') == "financemanager"){
        this.logintrue = true;
        this.router.navigateByUrl('/admin/finance-report')
      }
      else if(sessionStorage.getItem('role') == "clienttestrole"){
        this.logintrue = true;
        this.router.navigateByUrl('/agent/conciergeCustomerAlerts')
      }
      else{
        this.logintrue = true;
        this.router.navigateByUrl('/agent/policy-search')
      }
    }
    else {
      // console.log('stay in login')
    }
  }

  public getToken(): string {    
    let token = sessionStorage.getItem('token');
    return token;
  }

  public isAuthenticated(): boolean {
    // console.log("came to authenticate...")
    let auth: boolean;
    const token = this.getToken();
      try {
            let tokeData = jwt_decode(token);
            let  url = this.router.url;
            let user = url.split('/')[1];
            // console.log(true);
            auth = true;
      } catch (e) {
          // console.log("error decoding token");
          // console.log(false);
          auth = false;
      }
      return auth;
  }

  onSignInClick() {
    this.isSignInClick = true;
    if (this.userName && this.password) {
      this.authService.signIn(this.userName, this.password)
        .subscribe((result: any) => {
          // this.result = result;
          if (result.status == "success") {
            this.signin = true;
          }
        }, err => {
          this.isLoginError=true;
        })

        ;
    }
    else {
      // var toastOptions = this.searchService.setToastOptions('Sign In Errors', 'Please enter username and password', '')
      // this.toastaService.error(toastOptions);
    }
  }

  checkOTP() {
      this.invalidotp = false;
      this.authService.checkOtp(this.otp, this.userName).subscribe((data:any) => {
        if(data.status == false) {
          this.invalidotp = true;
          return
        }
        else {
          this.result = data        
          sessionStorage.setItem('user_name', this.result.user.user_name);
          sessionStorage.setItem('_id', this.result.user._id);
          sessionStorage.setItem('token', this.result.user.token);
          sessionStorage.setItem('email', this.result.user.email);
          sessionStorage.setItem('role', this.result.user.role_name);
          sessionStorage.setItem('firstName', this.result.user.fName);
          sessionStorage.setItem('lastName', this.result.user.lName);
          sessionStorage.setItem('role_permissions', JSON.stringify(this.result.user.role_permissions));
          if(this.result.user.role_name == "admin" || sessionStorage.getItem('role') == "superadmin"){
            this.logintrue = true;
            this.router.navigateByUrl('/admin/generalMasterReports')
          }
          else if(sessionStorage.getItem('role') == "financemanager"){
            this.logintrue = true;
            this.router.navigateByUrl('/admin/finance-report')
          }
          else if(this.result.user.role_name == "ClientRole"){
            this.logintrue = true;
            this.router.navigateByUrl('/agent/conciergeCustomerAlerts')
          }
          else if (this.result.user.role_name == "clienttestrole") {
            this.logintrue = true;
            this.router.navigateByUrl('/agent/conciergeCustomerAlerts')
          }
          else{
            this.logintrue = true;
            this.router.navigateByUrl('/agent/policy-search')
          }
        }     
      })
      // if (this.otp == this.randomnumber || this.otp == 123456) {
      // sessionStorage.setItem('user_name', this.result.user.user_name);
      // sessionStorage.setItem('_id', this.result.user._id);
      // sessionStorage.setItem('token', this.result.user.token);
      // sessionStorage.setItem('email', this.result.user.email);
      // sessionStorage.setItem('role', this.result.user.role_name);
      // sessionStorage.setItem('firstName', this.result.user.fName);
      // sessionStorage.setItem('lastName', this.result.user.lName);
      // sessionStorage.setItem('role_permissions', JSON.stringify(this.result.user.role_permissions));
      //   if(this.result.user.role_name == "admin" || sessionStorage.getItem('role') == "superadmin"){
      //     this.logintrue = true;
      //     this.router.navigateByUrl('/admin/generalMasterReports')
      //   }
      //   else if(sessionStorage.getItem('role') == "financemanager"){
      //     this.logintrue = true;
      //     this.router.navigateByUrl('/admin/finance-report')
      //   }
      //   else if(this.result.user.role_name == "ClientRole"){
      //     this.logintrue = true;
      //     this.router.navigateByUrl('/agent/conciergeCustomerAlerts')
      //   }
      //   else{
      //     this.logintrue = true;
      //     this.router.navigateByUrl('/agent/policy-search')
      //   }            
      // }
      // else {
      //   // console.log("invalid otp");
      //   this.invalidotp = true;
      // }

  }

  back() {
    this.isSignInClick = false;
    this.userName = null;
    this.password = null;
    this.signin = false;
    this.result = null;
  }

  encrypt(value) {
    this.encryptedData = CryptoJS.AES.encrypt(JSON.stringify(this.encdataToEncrypt), this.secretKey).toString()
    // console.log(this.encryptedData, 'encrypt')
  }
  decrypt() {
    let bytes = CryptoJS.AES.decrypt(this.encryptedData, this.secretKey)
    var obj = JSON.parse(bytes.toString(CryptoJS.enc.Utf8))
    // console.log(obj.id, 'id');
    // console.log(obj.name, 'name');
  }
  getUserDetailsByEmail() {
    let email = this.emailForm.value.email
    // console.log(email, 'email')
    this.authService.getUserDetailsByEmail(email).subscribe((data:any) => {
      if(data.length) {
        // console.log(data[0], 'user data')
        let currenttime = new Date(data[0].currenttime).getTime();
        let link = this.webUrl+'/'+'resetpassword/'+data[0]._id+'/'+currenttime        
        let form = {
          email: data[0].email,
          name: '',
          subject: "Reset Password Link, This link will be expired in 30 minutes",
          body: link
        }
        this.authService.sendMessage(form).subscribe(res => {
          this.sentmail = true;
          this.emailForm.get('email').setValue('');
        })
      }
      else {
        this.toastr.warning("Account Does not Exist with this Email Address");
      }
      

    })
  }

  resetStage() {
    this.sentmail = false;
    this.emailForm.get('email').setValue('');
  }

  shoowPassword() {
    this.passwordHide = !this.passwordHide
  }
  
}
