import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router,ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ToastrService } from 'ngx-toastr';
import * as jwt_decode from "jwt-decode";
import * as CryptoJS from 'crypto-js';

@Component({
  selector: 'app-csat',
  templateUrl: './csat.component.html',
  styleUrls: ['./csat.component.scss']
})
export class CSATComponent implements OnInit {
  feedback:any;
  ratingValue:any;
  npsRating:any;
  showContainer:Boolean=false
  showFirstFeedback:Boolean=false
  showSecondFeedback:Boolean=false
  showThirdFeedback:Boolean=false
  showNpsContainer:Boolean = false
  showFinalContainer:Boolean = false
  ShowCsatContainer:Boolean = true;
  showContainerForExpiredLink:Boolean = false;
  forCsatButton:Boolean
  caseNumber:any;
  clientName:any;
  clientId:any;
  pathOfLogo:any
  result: any;
  scaleNumbers: number[];
  remainingCharacters: number = 500;
  csatSmsDate:any;
  openedBy:any;
  serviceId:any;
  caseObjId:any;
  constructor(private authService: AuthService, private router: Router,private route: ActivatedRoute, private formBuilder: FormBuilder, private toastr: ToastrService) { }

  ngOnInit() {
    this.route.params.subscribe(params => {
      // const encryptedCaseNumber = params['caseNumber'];
      // const encryptedClientId = params['clientId'];
      // const encryptedcreationDate = params['creationDate'];
      // this.caseNumber = this.decryptData(encryptedCaseNumber);
      // this.clientId = this.decryptData(encryptedClientId);
      // this.csatSmsDate = this.decryptData(encryptedcreationDate);
      const encryptedData = params['encryptedData'];
      const decryptedData = this.decryptData(encryptedData);
      const [caseNumber, clientId, creationDate,openedBy,serviceNo,caseObjId] = decryptedData.split('/');
      this.caseNumber = caseNumber;
      this.clientId = clientId;
      this.csatSmsDate = creationDate;
      this.openedBy = openedBy
      this.serviceId = serviceNo
      this.caseObjId = caseObjId
      // this.caseNumber = params['caseNumber'];
      // this.clientId = params['clientId'];
      // this.csatSmsDate = params['creationDate'];
      // this.encryptData(this.caseNumber);
      // this.encryptData(this.clientId);
    });
    this.processCsatStatusAndLinkExpiration(this.serviceId,this.csatSmsDate)
    // this.getCsatSubmitStatus(this.caseNumber)
    // this.linkExpire(this.csatSmsDate)
    this.getclientName(this.clientId);
    this.scaleNumbers= Array.from({length: 11}, (_, i) => i); 
  }

  updateCharacterCount() {
    this.remainingCharacters = 500 - this.feedback.length;
  }
  // linkExpire(date: string) {
  //   let diff = new Date().getTime() - new Date(date).getTime();

  //   let diffInMilliSeconds = diff / 1000;
  //   if (diffInMilliSeconds < 0)
  //     diffInMilliSeconds = 0;

  //   let hours = Math.floor(diffInMilliSeconds / 3600);
  //   console.log(hours, "time in hours")
  //   if (hours > 48) {
  //     this.showContainerForExpiredLink = true
  //     this.showFinalContainer = false;
  //     this.ShowCsatContainer = false;
  //     this.showNpsContainer = false;
  //     this.showContainer = false;
  //   }
  // }
  processCsatStatusAndLinkExpiration(serviceNo: string, csatSDate: string) {
    // Check CSAT submit status
    this.authService.getcsatSubmitStatus(serviceNo).subscribe((response: any) => {
      if (response && response.length > 0 && response[0].status === "submitted") {
        this.showContainerForExpiredLink = true;
        this.showFinalContainer = false;
        this.ShowCsatContainer = false;
        this.showNpsContainer = false;
        this.showContainer = false;
      } else {
        // Calculate link expiration
        let diff = new Date().getTime() - new Date(csatSDate).getTime();
        let diffInMilliSeconds = diff / 1000;
        if (diffInMilliSeconds < 0)
          diffInMilliSeconds = 0;
        
        let hours = Math.floor(diffInMilliSeconds / 3600);
        console.log(hours, "time in hours");
        if (hours > 48) {
          this.showContainerForExpiredLink = true;
          this.showFinalContainer = false;
          this.ShowCsatContainer = false;
          this.showNpsContainer = false;
          this.showContainer = false;
        }
      }
    });
  }
  
  capitalizeFirstLetter(name: string): string {
    if (typeof name !== 'string' || name.trim() === '') {
        return ''; // Return empty string if name is not a non-empty string
    }

    const parts = name.split(' ');

    if (parts.length > 1) {
        // Capitalize the first letter of each part
        return parts.map(part => part.charAt(0).toUpperCase() + part.slice(1).toLowerCase()).join(' ');
    } else {
        // If no space, capitalize the first letter only
        return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
    }
}



  rateExperience(rating:number){
    this.ratingValue = rating
    console.log(rating,"ratingggggg")
    if(this.ratingValue ==1 || this.ratingValue==2){
      this.showContainer = true;
      this.showFirstFeedback=true;
      this.showSecondFeedback=false;
      this.showThirdFeedback=false;
      this.forCsatButton =true
    }
    else{
      this.showContainer = true;
      this.showSecondFeedback=true;
      this.showFirstFeedback=false;
      this.showThirdFeedback=false;
      this.forCsatButton =true
    }

  }
  submitFeedback(){
    console.log(this.feedback)
    let data = {
      "rating":String(this.ratingValue),
      "remarks":this.feedback,
      "caseNumber":this.caseNumber,
      "clientName":this.clientName,
      "openedBy":this.openedBy,
      "serviceNo":this.serviceId
    }
    console.log(typeof data.rating,"dataaaa")
    this.authService.submitScore(data).subscribe((result: any) => {
        this.result = result._id;
        this.showNpsContainer = true;
        this.ShowCsatContainer = false;
        this.showContainer = false;
        this.showFirstFeedback = false;
        this.showSecondFeedback = false;
        this.feedback="";
        this.forCsatButton = false
      }, err => {
      })
  }
  submitNpsFeedback(){
    let data = {
      "_id":this.result,
      "npsRating":String(this.npsRating),
      "npsRemarks":this.feedback,
      "caseNumber":this.caseNumber,
      "clientName":this.clientName,
      "caseObjId" :this.caseObjId
    }
    this.authService.submitNpsScore(data).subscribe((data: any) => {
      this.showFinalContainer = true;
      this.ShowCsatContainer = false;
      this.showNpsContainer = false;
      this.showContainer = false;
      setTimeout(() => {
        window.close();
      }, 1000);
    }, err => {
    })
  }
  rateNps(npsScore){
    this.npsRating = npsScore;
    this.showContainer = true;
    this.showThirdFeedback = true;
    console.log(this.npsRating,"npsRating")
  }

  getclientLogo(serviceProvider) {
    this.authService.getclientLogo(serviceProvider).subscribe((response:any) => {
      let encryptedData = response.data;
      let decryptedData = this.decryptData(encryptedData);
      let clientLogoUrl = JSON.parse(decryptedData);
      let urlObject = new URL(clientLogoUrl[0].clientData.clientLogo);
      this.pathOfLogo = urlObject.pathname;
    })
  }
  getclientName(serviceProviderName) {
    this.authService.getclientName(serviceProviderName).subscribe((response:any) => {
      this.clientName = response[0].clientName;
      this.getclientLogo(this.clientName);
    })
  }
  // getCsatSubmitStatus(caseNumber) {
  //   this.authService.getcsatSubmitStatus(caseNumber).subscribe((response:any) => {
  //     if (response && response.length > 0) {
  //       let data = response[0];
  //       if (data.status === "submitted") {
  //         this.showContainerForExpiredLink = true;
  //         this.showFinalContainer = false;
  //         this.ShowCsatContainer = false;
  //         this.showNpsContainer = false;
  //         this.showContainer = false;
  //       }
  //     }
  //   })
  // }
  decryptData(encryptedData: string): string {
    const secretKey = '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'; // Same secret key used for encryption
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return bytes.toString(CryptoJS.enc.Utf8);
  }
  encryptData(caseId: string){
    console.log(caseId,"value") 
    const secretKey = '!hrv7PSJxkzTy#g!+=KzsbLcmU4fW4tgZEr_4WkR'; // Replace with your secret key
    const encrypted = CryptoJS.AES.encrypt(caseId, secretKey).toString();
    console.log(encrypted,"Case Number") 
  }
  
}

